import numeral from 'numeral';

export default {
  created() {
    try {
      numeral.locale(this.$i18n.locale);
      numeral.register('locale', 'hu', {
        delimiters: {
          thousands: ' ',
          decimal: ',',
        },
        abbreviations: {
          thousand: 'e',
          million: 'm',
          billion: 'b',
          trillion: 't',
        },
        currency: {
          symbol: 'HUF',
        },
      });
    } catch (e) {
      if (e.message.includes('hu locale already registered')) {
        console.warn('hu locale already registered');
      }
    }
  },
};
